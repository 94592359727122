/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap"); */

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v11/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v11/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v11/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'GT Eesti Display';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/gt_eesti/GT-Eesti-Display-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'GT Eesti Display';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/gt_eesti/GT-Eesti-Display-Medium.woff2')
    format('woff2');
}

@font-face {
  font-family: 'GT Eesti Display';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/gt_eesti/GT-Eesti-Display-Bold.woff2') format('woff2');
}

html {
  --color-white: #fff;
  --color-text: #1a1b31;
  --color-primary: #e04e0c;
  --font-main-family: 'Work Sans', -apple-system, sans-serif;
  --font-default-size: 16px;
}

::-webkit-scrollbar {
  display: none;
}

body {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-flow: column nowrap;

  flex: 1 1 100%;
  min-height: 100vh;
}

body {
  font-family: var(--font-main-family);
  font-weight: 400;
  font-size: var(--font-default-size);
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-white);
  color: var(--color-text);
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

input,
textarea,
button {
  font-family: inherit;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.4;
  }

  60% {
    transform: scale(0.9);
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

#layout-topbar,
#layout-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

@media only screen and (min-width: 1024px) {
  #layout-header {
    top: 4.5rem;
  }
}
